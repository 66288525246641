import Keycloak from "keycloak-js";
import { Redirect } from "react-router-dom";
import LoginService from "../services/loginService";
import UserService from "../services/userService";

const _kc = new Keycloak({
  url: process.env.REACT_APP_KEY_CLOAK_URL_AUTH,
  realm: process.env.REACT_APP_KEY_CLOAK_REALM,
  clientId: process.env.REACT_APP_KEY_CLOAK_CLIENTID,
});
/**
 * Initializes Keycloak instance and calls the provided callback function if successfully authenticated.
 *
 * @param callback
 */

window.onhashchange = (info) => {
  if (info.newURL.substr(-2) != "#/") {
    localStorage.setItem("url", info.newURL);
  }
};

const initKeycloak = (callback) => {
  return new Promise((resolve, reject) => {
    _kc
      .init({
        onLoad: "check-sso",
        silentCheckSsoRedirectUri: window.location.origin + "/silent-check-sso.html",
        checkLoginIframe: false,
        redirectUri:
          process.env.NODE_ENV !== "development" ? process.env.REACT_APP_LINK : undefined,
      })
      .then((authenticated) => {
        const loadedAuthStorage = JSON.parse(localStorage.getItem("currentUser"));
        if (authenticated && !loadedAuthStorage) {
          LoginService.login({
            email: AuthKeycloak.getEmail(),
          }).then(
            (currentUser) => {
              localStorage.setItem("currentUser", JSON.stringify(currentUser.data));
              UserService.getUserCompanies(currentUser.data.user.id).then((response) => {
                localStorage.setItem(
                  "company",
                  response.data[0]
                    ? JSON.stringify(response.data[0])
                    : JSON.stringify(response.data)
                );
                localStorage.setItem("user_companies", JSON.stringify(response.data));
                window.location.href = "/#/home";
                callback();
              });
            },
            (error) => {
              console.log(error);
            }
          );
        } else {
          callback();
        }
      })
      .catch((e) => {
        if (e.error == "access_denied") {
          callback();
        }
        console.error(e);
      });
  });

  // .catch(console.error);
};

const doLogin = _kc.login;

const doLogout = _kc.logout;

// const getToken = () => {
//   return _kc.token;
// };

const getToken = () => {
  return new Promise((resolve, reject) => {
    if (_kc.authenticated && _kc.isTokenExpired()) {
      _kc.updateToken(180).then((updated) => {
        resolve(_kc.token);
      });
    } else {
      resolve(_kc.token);
    }
  });
};

const isLoggedIn = () => {
  return !!_kc.token;
};
const token = () => _kc.token;
const updateToken = (successCallback) => _kc.updateToken(5).then(successCallback).catch(doLogin);

const getUsername = () => _kc.tokenParsed?.preferred_username;
const getEmail = () => _kc.tokenParsed?.email;

const hasRole = (roles) => roles.some((role) => _kc.hasRealmRole(role));

const AuthKeycloak = {
  initKeycloak,
  doLogin,
  doLogout,
  isLoggedIn,
  getToken,
  updateToken,
  getUsername,
  hasRole,
  getEmail,
  token,
};

export default AuthKeycloak;
