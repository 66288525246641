import React, { Suspense, useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-datepicker/dist/react-datepicker.css";
import "./base.scss";
import "./landing/App.css";
import { HashRouter, Switch, Route, useLocation } from "react-router-dom";
import SharedContext from "./SharedContext";

//Dashboard
const NavBar = React.lazy(() => import("./components/nav-bar/navbar"));
const SideBar = React.lazy(() => import("./components/side-bar/sidebar"));
const View = React.lazy(() => import("./components/view/view"));

//Landing
const Home = React.lazy(() => import("./landing/components/home/home"));
const Footer = React.lazy(() => import("./landing/components/footer/footer"));
const Navbar = React.lazy(() => import("./landing/components/navbar/navbar"));
const Products = React.lazy(() => import("./landing/components/products/products"));
const Product = React.lazy(() => import("./landing/components/products/product"));

const currentYear =
  new Date().getMonth() == 0 ? new Date().getFullYear() - 1 : new Date().getFullYear();
const currentMonth = new Date().getMonth() == 0 ? 11 : new Date().getMonth() - 1;
const currentDate = new Date(currentYear, currentMonth);
const company = JSON.parse(localStorage.getItem("company"));

function Root() {
  const [shared, setShared] = useState({
    index: sessionStorage.getItem("index") || 0,
    selectedDate: currentDate,
    sidebar: true,
    content: false,
    informal: company?.document_type == 0,
  });
  const [notification, setNotification] = useState(0);

  const ScrollToTop = () => {
    const { pathname, search } = useLocation();

    useEffect(() => {
      window.scrollTo(0, 0);
    }, [pathname, search]);

    return null;
  };

  const setDate = (date) => {
    shared.selectedDate = date;
    setShared({ ...shared });
  };

  const toggleSidebar = (value) => {
    shared.sidebar = value;
    setShared({ ...shared });
  };

  const setInformal = (value) => {
    shared.informal = value == 0;
    setShared({ ...shared });
  };

  return (
    <div style={{ backgroundColor: "#F5F5F5" }}>
      <HashRouter>
        <ScrollToTop />
        <Suspense fallback={<div></div>}>
          <SharedContext.Provider value={shared}>
            <Switch>
              <Route path="/app">
                <NavBar
                  setNotification={setNotification}
                  notification={notification}
                  toggleSidebar={toggleSidebar}
                />
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <SideBar toggleSidebar={toggleSidebar} />
                  <View setDate={setDate} setInformal={setInformal} />
                </div>
              </Route>
              <Route path="/products">
                <Navbar />
                <Products />
                <Footer />
              </Route>
              <Route path="/product">
                <Navbar />
                <Product />
                <Footer />
              </Route>
              <Route path="/">
                <Navbar />
                <Home />
                <Footer />
              </Route>
            </Switch>
          </SharedContext.Provider>
        </Suspense>
      </HashRouter>
    </div>
  );
}

export default Root;
